import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Pluralize from 'pluralize'

import { ResultsAndRecommendationDescription } from '../../../components/descriptions'
import { DogDetails, Whistle } from '../../../lib/services'

import {
  ActivityLevels,
  Navigation,
  RecommendationCard,
  HeadingLabel,
  PuppyFeedingTips,
  Icon,
} from '../../../components'

import PlaceholderCards from './placeholder-cards'

import './style.css'

class ResultsAndRecommendations extends Component {

  constructor(props) {
    super(props)

    const name = localStorage.getItem("name")
    const breed = localStorage.getItem("breed")
    const breedName = localStorage.getItem("breedName")
    const breedId = localStorage.getItem("breedId")
    const foodName = localStorage.getItem("foodName")
    const foodNotFound = localStorage.getItem("foodNotFound")
    const activityLevel = parseInt(localStorage.getItem("activityLevel"))

    this.state = {
      name: name,
      breed: breedId ? breedName : breed,
      activityLevel: activityLevel,
      foodName: foodName,
      foodNotFound: foodNotFound,
      resultsFetched: false,
    }

    this.requestResults()
  }

  /**
   * Request results from the Whistle API and set
   * state when properly consumed. Errors currently
   * throw an alert (not ideal).
   */
  requestResults = () => {
    const { activityLevel } = this.state
    Whistle.submit(DogDetails.fromLocalStorage())
    .then(data => {
      if (data.error) {
        throw(data.error)
      }

      localStorage.setItem("results", JSON.stringify(data))
      localStorage.setItem("treats", JSON.stringify(data.treats))
      this.setPortion(data, activityLevel)
      this.setState({
        results: data,
        resultsFetched: true,
      })
    })
    .catch(err => {
      alert(err)
    })
  }

  /**
   * Called when a user selects a different activity level.
   * This will set state and update portion if results have
   * been fetched from the API.
   */
  selectActivityLevel = activityLevel => {
    this.setState({ activityLevel: parseInt(activityLevel) })

    const { 
      resultsFetched,
      results,
    } = this.state
    if (!resultsFetched) return;

    this.setPortion(results, activityLevel)
  }

  /**
   * Set the recommended portion based on the results
   * and selected activity level. Sets state but also
   * returns portion.
   */
  setPortion = (results, activityLevel) => {
    if (!results.portion_amounts) {
      return
    }

    const normalizedActivitylevel = DogDetails.normalizedActivitylevel(activityLevel)
    const portion = results.portion_amounts[normalizedActivitylevel]
    this.setState({ portion: portion })

    return portion
  }

  /**
   * Creates a string that can be rendered for recommended activity
   * based on results from the Whistle API. 
   */
  activityMinutes = portion => {
    var activityRecommendation = ""
    switch(true) {
      case portion.minutes_active_min === null:
          activityRecommendation = `Up to ${portion.minutes_active_max} min`  
      break;
      case portion.minutes_active_max === null:
        activityRecommendation = `At least ${portion.minutes_active_min} min`
      break;
      case portion.minutes_active_max === portion.minutes_active_min:
        activityRecommendation = `${portion.minutes_active_max} min`
      break;
      case portion.minutes_active_max !== portion.minutes_active_min:
          activityRecommendation = `Between ${portion.minutes_active_min} and ${portion.minutes_active_max} min`
      break;
      default:
          activityRecommendation = `${portion.minutes_active_max} min`
    }
    return activityRecommendation
  }

  render() {
    const { 
      activityLevel,
      name,
      breed,
      resultsFetched,
      portion,
      results,
    } = this.state
    const pluralizedBreed = Pluralize.plural(breed)
    return (
      <div>
        <div className="container">
          <ResultsAndRecommendationDescription />
          <div className="row">
            <Navigation activeStep={3} />
          </div>
          <HeadingLabel>{`We recommend the following for ${name}`}</HeadingLabel>
          <div className="row center-align how-did-we-get-these-numbers-container">
            <a href="https://support.whistle.com/hc/en-us/articles/360039436234" target="_blank" rel="noopener noreferrer" className="how-did-we-get-these-numbers">
              <Icon /><span>How did we get these numbers?</span>
            </a>
          </div>
          { !resultsFetched &&
            <PlaceholderCards />
          }
          { resultsFetched && results.portion_amounts === null &&
            <div className="row">
              <p className="col s12 m10 offset-m1 l10 offset-l1 center-align puppy-sad-news">Unfortunetly, to ensure your puppy’s safety, we can’t provide a food portion suggestion until your puppy has reached maturity. Just as your puppy grows and changes week to week, their feeding requirements do too. Consult a vet to ensure a safe feeding regimen for your puppy before making any changes.  Here are some tips for feeding puppies after they have been weaned onto solid food. </p>
            </div>
          }
          { resultsFetched && results.portion_amounts !== null &&
            <div className="row center-align recommendation-card-container">
              <RecommendationCard
                bannerColor={"green"}
                bannerLabel={name}
                title={"Suggested Daily Food"}
                description={results.pet_food.name !== "Pet food not found" ? `of ${results.pet_food.name}` : ''}
                headline={`${portion.portion_name} ${portion.unit}`}
                classes={"s12 m6 l4 offset-l2"}
              />
              <RecommendationCard
                bannerColor={"gray"}
                bannerLabel={breed}
                title={"Activity Suggestion"}
                description={`${pluralizedBreed} that get at least this much daily activity are most likely to maintain an ideal weight.`}
                headline={`${Math.round(results.average_breed_activity)} min`}
                classes={"s12 m6 l4 offset-l1"}
              />
            </div>
          }
          { resultsFetched && results.portion_amounts === null &&
            <div className="row center-align recommendation-card-container">
              <RecommendationCard
                bannerColor={"gray"}
                bannerLabel={breed}
                title={"Activity Suggestion"}
                description={`${pluralizedBreed} that get at least this much daily activity are most likely to maintain an ideal weight.`}
                headline={"50 minutes"}
                classes={"s12 m6 l4"}
              />
              <PuppyFeedingTips />
            </div>
          }
          { resultsFetched && results.portion_amounts !== null &&
            <div className="row center-align what-would-you-like-to-achieve-container">
              <h5>{`What activity level is ${name}?`}</h5>
              <p>Select from the options below to change the results.</p>
            </div>
          }
        </div>
        { resultsFetched && results.portion_amounts !== null &&
          <ActivityLevels
            activityLevel={activityLevel}
            onSelected={this.selectActivityLevel}
          />
        }
        { resultsFetched && results.portion_amounts !== null &&
          <div className="treats-link-container row container center-align">
            <HeadingLabel>Do you know how much treats and table scraps can add up?</HeadingLabel>
            <Link to="/results-and-recommendations/treats" className="btn submit treats-btn col s12 m4 offset-m4 valid">Learn About Treats</Link>
          </div>
        }
      </div>
    )  
   }
}

export default ResultsAndRecommendations