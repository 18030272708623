import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './style.css'

class BCS extends Component {
  
  render() {
    const {
      id,
      image,
      title,
      description,
      descriptionTitle,
      selected,
      onSelected,
      containerClass,
    } = this.props
    return (
      <div className={`bcs col ${containerClass} ${selected ? "selected" : ""}`} data-testid="card-container">
        <div className="bcs-card center-align" onClick={() => { onSelected(id) }} data-testid="card">
          <i className="material-icons">check_circle</i>
          <div className="bcs-image-container">
            <img className="bcs-image" src={image} alt={`${title}`} />
          </div>
          <h4 className="bcs-title">{title}</h4>
          { descriptionTitle &&
            <h5 className="bcs-description-title">{descriptionTitle}</h5>
          }
          <p className="bcs-description">{description}</p>
        </div>
      </div>
    )
  }
}

BCS.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  descriptionTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  selected: PropTypes.bool.isRequired,
  onSelected: PropTypes.func.isRequired,
  containerClass: PropTypes.string,
  image: PropTypes.string,
}

BCS.defaultProps = {
  containerClass: "s12 m6 l2",
  image: "",
}

export default BCS