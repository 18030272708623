class DogDetails {

  /**
   * Create an object that can be passed to the Whistle API
   * for results.
   */
  static fromLocalStorage() {
    return {
      breed_id:             parseInt(localStorage.getItem("breedId")),
      pet_food_id:          parseInt(localStorage.getItem("foodId")),
      age_month:            parseInt(localStorage.getItem("months")),
      age_year:             parseInt(localStorage.getItem("years")),
      weight:               parseInt(localStorage.getItem("weight")),
      weight_unit:          DogDetails.normalizedWeightUnit(localStorage.getItem("weightUnits")),
      body_condition_score: DogDetails.normalizedBCS(localStorage.getItem("bcs")),
      activity_level:       DogDetails.normalizedActivitylevel(localStorage.getItem("activityLevel")),
      is_fixed:             localStorage.getItem("fixed") === "Spayed" || localStorage.getItem("fixed") === "Neutered" ? true : false,
      gender:               localStorage.getItem("gender").toLocaleLowerCase(),
      name:                 localStorage.getItem("ownerName"),
      email:                localStorage.getItem("ownerEmail"),
      pet_name:             localStorage.getItem("name")
    }
  }

  /**
   * Normalize weight units for Whistle API request.
   * Takes strings "Kilograms (kg)" and "Pounds (lb)"
   * and returns "kg" and "lb" respectively.
   * 
   * @param {string} weightUnit 
   */
  static normalizedWeightUnit(weightUnit) {
    return weightUnit === "Kilograms (kg)" ? "kg" : "lb"
  }

  /**
   * Normalizes BCS socre for Whistle API request.
   * Takes bcs number 1-5 and returns string that
   * API understands. Although bcs 1 & 5 should never
   * be sent to the API they are included here
   * 
   * @param {number} bcs 
   */
  static normalizedBCS(bcs) {
    var nBCS = ""

    switch(parseInt(bcs)) {
      case 1:
        nBCS = "very_thin"
      break;

      case 2:
        nBCS = "underweight"
      break;

      case 3:
        nBCS = "ideal"
      break;

      case 4:
        nBCS = "overweight"
      break;

      case 5:
        nBCS = "obese"
      break;

      default:
        nBCS = ""
    }
    return nBCS
  }

  /**
   * Normalizes activity level for Whistle API.
   * Takes an activity level as a number and returns
   * a string that the API understands.
   * 
   * @param {number} activityLevel 
   */
  static normalizedActivitylevel(activityLevel) {
    var nActivityLevel = ""

    switch(parseInt(activityLevel)) {
      case 1:
        nActivityLevel = "couch_potato"
      break;

      case 2:
        nActivityLevel = "regular_mover"
      break;

      case 3:
        nActivityLevel = "weekend_warrior"
      break;

      case 4:
        nActivityLevel = "the_athlete"
      break;

      default: 
        nActivityLevel = ""
    }

    return nActivityLevel
  }
}

export default DogDetails