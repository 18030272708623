import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './style.css'

class Alert extends Component {

  render() {
    const {
      title,
      children,
      icon,
      displayIcon,
    } = this.props
    return (
      <div className="alert-container row center-align">
        <label className="alert-container--title row col s12 m7 offset-m3">
          { displayIcon && icon &&
            <span className={`icon alert-container--icon`}><img src={icon} alt="alert-icon" /></span>
          }
          <span className="alert-container--title-text">{title}</span>
        </label>
        <p className="alert-container--description row col s12 m7 offset-m3">{children}</p>
      </div>
    )
  }
}

Alert.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  displayIcon: PropTypes.bool,
}

Alert.defaultProps = {
  title: "",
  icon: require('../../images/nutrition-flow-icons/Whistle_Icons_Dec2019_DYK.png'),
  displayIcon: true,
}

export default Alert