import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import { createBrowserHistory } from 'history'

import {
  NameRoute,
  SelectGenderRoute,
  SpayedOrNeuteredRoute,
  BreedRoute,
  EnterAgeRoute,
  EnterWeightRoute,
  BCSRoute,
  SelectFoodRoute,
  PregnantOrNursingRoute,
  ActivityLevelRoute,
  ResultsAndRecommendationsRoute,
  TreatsRoute,
} from './routes'

import Headers from './components/headers'
import { PostMessage } from './components'

const history = createBrowserHistory()

class App extends React.Component {

  render() {
    return (
      <div>
        <Headers />
        <PostMessage />
        <Router history={history}>
          <div>
            <Switch>
              <Route exact path="/" component={NameRoute} />
              <Route path="/pet-details/enter-name" component={NameRoute} />
              <Route path="/pet-details/select-gender" component={SelectGenderRoute} />
              <Route path="/pet-details/spayed-or-neutered" component={SpayedOrNeuteredRoute} />
              <Route path="/pet-details/pregnant-or-nursing" component={PregnantOrNursingRoute} />
              <Route path="/pet-details/select-breed" component={BreedRoute} />
              <Route path="/pet-details/enter-age" component={EnterAgeRoute} />
              <Route path="/pet-details/enter-weight" component={EnterWeightRoute} />
              <Route path="/body-condition-score" component={BCSRoute} />
              <Route path="/food-and-activity-levels/select-food" component={SelectFoodRoute} />
              <Route path="/food-and-activity-levels/activity-level" component={ActivityLevelRoute} />
              <Route exact path="/results-and-recommendations" component={ResultsAndRecommendationsRoute} />
              <Route path="/results-and-recommendations/treats" component={TreatsRoute} />
            </Switch>
          </div>
        </Router>
      </div>
    )
  }
}
export default App;
