import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Autosuggest from 'react-autosuggest'

import './style.css'

class Autocomplete extends Component {

  constructor(props) {
    super(props)

    this.state = {
      suggestions: [],
    }
  }

  /**
   * Get suggestions based on value that is
   * currently in the input field
   */
  getSuggestions = value => {
    return this.props.getSuggestions(value)
  }

  /**
   * When a suggestion is clicked, auto suggests
   * then populated the input with this suggestion.
   */
  getSuggestionValue = suggestion => suggestion.name

  /**
   * Will call this function every time we need to 
   * update suggestions
   */
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    })
  }

  /**
   * Will call this function every time we need to 
   * clear suggestions
   */
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    })
  }

  /**
   * Render an autosuggest element
   */
  renderSuggestion = suggestion => (
    <a href={`#${suggestion.name}`} className="btn-flat col s12 suggestion">
      {suggestion.name}
    </a>
  )

  renderSuggestionsContainer = ({ containerProps, children, query }) => {
    return (
      <div className="render-suggestions-top-level-container row col s12 m6 offset-m3">
        <div {... containerProps}>
          {children}
        </div>
      </div>
    )
  }

  render() {
    const { suggestions } = this.state
    const { 
      inputProps,
      getSuggestionValue,
      renderSuggestion,
      onSuggestionSelected,
    } = this.props
    return (
      <Autosuggest
        data-testid={"autocomplete"}
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue || this.getSuggestionValue}
        renderSuggestion={renderSuggestion || this.renderSuggestion}
        renderSuggestionsContainer={this.renderSuggestionsContainer}
        onSuggestionSelected={onSuggestionSelected}
        inputProps={inputProps}
      />
    )
  }
}

Autocomplete.propTypes = {
  inputProps: PropTypes.object,
  getSuggestions: PropTypes.func,
  getSuggestionValue: PropTypes.func,
  renderSuggestion: PropTypes.func,
  onSuggestionSelected: PropTypes.func,
}

Autocomplete.defaultProps = {
  inputProps: {},
}

export default Autocomplete