import React, { Component } from 'react'
import { Placeholder } from '../../../components'

class PlaceholderCards extends Component {
  render() {
    return (
      <div className="row center-align">
        <div className="placeholder-container col s12 m6 l4 offset-l2">
          <Placeholder size={""} style={{ height: "300px"}}>
            <Placeholder size={""} style={{ height: "20px", width: "50%", marginBottom: "40px", marginTop: "20px" }} />
            <Placeholder size={""} style={{ height: "20px" }} />
            <Placeholder size={""} style={{ height: "20px", width: "75%", marginBottom: "40px"}} />
            <Placeholder size={""} style={{ height: "20px"}} />
            <Placeholder size={""} style={{ height: "20px"}} />
            <Placeholder size={""} style={{ height: "20px"}} />
          </Placeholder>
        </div>
        <div className="placeholer-container col s12 m6 l4 offset-l1">
          <Placeholder size={""} style={{ height: "300px"}}>
            <Placeholder size={""} style={{ height: "20px", width: "50%", marginBottom: "40px", marginTop: "20px" }} />
            <Placeholder size={""} style={{ height: "20px"}} />
            <Placeholder size={""} style={{ height: "20px", width: "75%", marginBottom: "40px"}} />
            <Placeholder size={""} style={{ height: "20px"}} />
            <Placeholder size={""} style={{ height: "20px"}} />
            <Placeholder size={""} style={{ height: "20px"}} />
          </Placeholder>
        </div>
      </div>
    )
  }
}

export default PlaceholderCards