import React, { Component } from 'react'

import {
  MultiSelect,
  HeadingLabel,
} from '../../../components'

import {
  PetDetailsContainer,
} from '../../../components/containers'

class EnterWeight extends Component {

  constructor(props) {
    super(props)

    const weight = localStorage.getItem("weight")
    const weightUnits = localStorage.getItem("weightUnits")

    this.state = {
      name: localStorage.getItem("name"),
      weight: weight,
      weightUnits: weightUnits,
      redirectTo: null,
    }
  }

  isValid = () => {
    const {
      weight,
      weightUnits,
    } = this.state

    if (isNaN(weight) || weight <= 0) {
      return false;
    }

    if (weightUnits === "Kilograms (kg)") {
      if (weight > 160){
        return false;
      }
    } else {
      if (weight > 350) {
        return false;
      }
    }

    if (!weightUnits) {
      return false;
    }

    return true;
  }

  onChange = e => {
    this.setState({
      [e.target.name]: parseInt(e.target.value),
    })
  }

  onSelectWeightUnits = value => {
    this.setState({
      weightUnits: value,
    })
  }

  onSubmit = e => {
    localStorage.setItem("weight", this.state.weight)
    localStorage.setItem("weightUnits", this.state.weightUnits)
    this.props.history.push('/body-condition-score')
  }

  render() {
    const {
      history,
    } = this.props
    const { 
      name,
      weight,
      weightUnits,
    } = this.state
    const valid = this.isValid()

    return (
      <PetDetailsContainer
        history={history}
        canSubmit={valid}
        onSubmit={this.onSubmit}
      >
        <HeadingLabel>{`How much does ${name} weigh?`}</HeadingLabel>
        <div className="row">
          <input type="number" id="weight" className="input-field rounded-input col s12 m3 offset-m1 l2 offset-l2" autoComplete="off" placeholder="Weight" name="weight" defaultValue={weight} onChange={this.onChange} />
          <MultiSelect
            options={["Kilograms (kg)", "Pounds (lb)"]}
            onSelect={this.onSelectWeightUnits}
            selected={weightUnits}
          />
        </div>
      </PetDetailsContainer>
    )  
   }
}

export default EnterWeight