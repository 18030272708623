import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './style.css'

class RecommendationCard extends Component {

  render() {
    const {
      bannerColor,
      bannerLabel,
      title,
      description,
      headline,
      classes,
    } = this.props
    return (
      <div className={`recommendation-card center-align col ${classes}`}>
        <div className="inner-container">
          <div className={`banner ${bannerColor}`}>
            <span>{bannerLabel}</span>
          </div>
          <h5 className="title">{title}</h5>
          <h3 className="headline">{headline}</h3>
          <p className="description">{description}</p>
        </div>
      </div>
    )
  }
}

RecommendationCard.propTypes = {
  bannerColor: PropTypes.string,
  bannerLabel: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  headline: PropTypes.string,
  classes: PropTypes.string,
}

RecommendationCard.defaultProps = {
  bannerColor: "green",
  bannerLabel: "",
  title: "title",
  description: PropTypes.string,
  headline: PropTypes.string,
  classes: "s12 m6 l3",
}

export default RecommendationCard