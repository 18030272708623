import React, { Component } from 'react'

import './style.css'

class HeadingLabel extends Component {

  render() {
    return (
      <div className="row center-align">
        <label className="green-label col s12">{this.props.children}</label>
      </div>
    )
  }
}

export default HeadingLabel
