import React, { Component } from 'react'
import Typekit from 'react-typekit'

class Headers extends Component {

  render() {
    return(
      <header>
        <Typekit kitId="wqt6sgx" />
      </header>
    )
  }

}

export default Headers