import React, { Component } from 'react'

import './style.css'

class HeadingDescription extends Component {
  render() {
    return (
      <div className="row center-align">
        <p className="heading-description col s12 m8 offset-m2">{this.props.children}</p>
      </div>
    )
  }
}

export default HeadingDescription