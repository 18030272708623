import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './style.css'

class Icon extends Component {

  render() {
    const {
      icon,
      width,
      styles,
    } = this.props
    return (
      <div className="nutrition-icon">
        <img src={icon} alt="nutrition-icon" style={styles} width={`${width}px`} />
      </div>
    )
  }
}

Icon.propTypes = {
  icon: PropTypes.string,
  width: PropTypes.number,
  styles: PropTypes.object,
}

Icon.defaultProps = {
  icon: require('../../images/nutrition-flow-icons/Whistle_Icons_Dec2019_DYK.png'),
  width: 20,
  styles: { },
}

export default Icon
