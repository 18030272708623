import { Component } from 'react'

class PostMessage extends Component {

  componentDidMount() {
    setInterval(function () {
      if (window.parent != null) {
        window.parent.postMessage({ height: document.body.clientHeight }, '*')
      }
    }, 100)
  }

  render() {
    return null
  }
}

export default PostMessage
