import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  Autocomplete,
  HeadingLabel,
  HeadingDescription,
} from '../../../components'

import {
  PetDetailsContainer,
} from '../../../components/containers'

import { Whistle } from '../../../lib/services'
import Text from '../../../lib/text'

class Breed extends Component {

  constructor(props) {
    super(props)

    const breedId = localStorage.getItem("breedId") || null

    this.state = {
      name: localStorage.getItem("name"),
      breedId: breedId,
      breedName: localStorage.getItem("breedName") || null,
      breed: localStorage.getItem("breedName") || '',
      breeds: {},
      valid: breedId != null,
      redirectTo: null,
    }

    Whistle.breedList().then(breeds => {
      this.setState({ breeds: breeds })
    })
  }

  /**
   * When the breed input value changes
   */
  onBreedDidChange = (e, { newValue }) => {
    this.setState({
      breed: newValue,
      breedId: null,
      breedName: null,
    })
  }

  /**
   * Get suggestions based on value that is
   * currently in the input field
   */
  getSuggestions = value => {
    const { breeds } = this.state
    const { numberResults } = this.props
    const val = value.trim().toLowerCase()

    if (!breeds.keys().length <= 0) {
      return []
    }

    const results = []
    for(let i = 0; i <= breeds.length; i++) {
      const breed = breeds[i]
      if (!breed) {
        break;
      }
      if (breed.name.toLowerCase().includes(val)) {
        results.push(breed)
      }
      if (results.length >= numberResults) {
        break;
      }
    }
    return results

    // return breeds.filter(breed => 
    //   breed.name.toLowerCase().includes(val)
    // )
  }

  /**
   * When a suggestion is clicked, auto suggests
   * then populated the input with this suggestion.
   */
  getSuggestionValue = suggestion => suggestion.name

  /**
   * Called when a suggestion is selected either by
   * keyboard or click
   */
  onSuggestionSelected = (event, { suggestion }) => {
    this.setState({
      breedId: suggestion.id,
      breedName: suggestion.name,
    })
  }

  onSubmit = e => {
    const {
      breedId,
      breedName,
    } = this.state
    localStorage.setItem("breedId", breedId)
    localStorage.setItem("breedName", breedName)
    this.props.history.push('/pet-details/enter-age')
  }

  render() {
    const { name, breed, breedName, breedId } = this.state
    const { history } = this.props
    const inputProps = {
      placeholder: "Type breed here",
      value: breed,
      onChange: this.onBreedDidChange,
      className: "input-field rounded-input col s12 m6 offset-m3",
    }
    const pluralizedName = Text.pluralize(name)
    const valid = breedName && breedId ? true : false
    return (
      <PetDetailsContainer
        history={history}
        canSubmit={valid}
        onSubmit={this.onSubmit}
      >
        <HeadingLabel>{`What is ${pluralizedName} breed?`}</HeadingLabel>
        <HeadingDescription>Use the dropdown to select the breed or mix that most closely relates to your pet. If your pet is a magical mix of all the things, select one that reflects your dog's size and body type, or select "other mix".</HeadingDescription>
        <div className="row">
          <Autocomplete
            inputProps={inputProps}
            getSuggestions={this.getSuggestions}
            getSuggestionValue={this.getSuggestionValue}
            onSuggestionSelected={this.onSuggestionSelected}
          />
        </div>
      </PetDetailsContainer>
    )  
   }
}

Breed.propTypes = {
  numberResults: PropTypes.number,
}

Breed.defaultProps = {
  numberResults: 20,
}

export default Breed
