class ActivityLevels {

  static formatActivityLevel(level) {
    if (level.minutes_active_min === null) {
      return `Under ${level.minutes_active_max} minutes of activity per day`
    } else if (level.minutes_active_max === null) {
      return `Over ${level.minutes_active_min} minutes of activity per day`
    } else {
      return `${level.minutes_active_min} - ${level.minutes_active_max} minutes of activity per day`
    }
  }
}

export default ActivityLevels