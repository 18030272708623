import React, { Component } from 'react'
import './style.css'

class ResultsAndRecommendationsDetails extends Component {

  render() {
    return (
      <div className="row description-container">
        <h1 className="description-title col s12">Your results</h1>
        <p className="description-text col s12 m8 offset-m2">Based on your answers and comparing to our breed specific Whistle activity and health database, here's our recommendation for your pet.</p>
      </div>
    )
  }
}

export default ResultsAndRecommendationsDetails
