import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './style.css'

class Placeholder extends Component {

    render() {
      const {
        size,
        style,
      } = this.props
      return (
        <div className={`placeholder col ${size}`} style={style}>
          {this.props.children}
        </div>
      )
    }
}

Placeholder.propTypes = {
  size: PropTypes.string,
  style: PropTypes.object,
}

Placeholder.defaultProps = {
  size: "s12 m4 l3",
  style: { height: "100px" },
}

export default Placeholder