import React, { Component } from 'react'

import './style.css'

class PuppyFeedingTips extends Component {

  render() {
    return (
      <div className="col s12 m6 l8 puppy-feeding-tips--container">
        <h2 className="puppy-feeding-tips--headline">Puppy Feeding Tips</h2>  
        <p><strong>Feed your puppy 3 - 4 times per day.</strong> More frequent and smaller meals throughout the day allows for easier digestion and more stable energy levels. As your puppy gets older, you can start to consult with your vet on cutting down the number of meals.</p>
        <p><strong>Feed large breed puppy food for large breed puppies.</strong> Large breed puppy food contains specific formulation to ensure your fast growing puppy gets enough calcium and nutrients to support proper bone growth. Deficiencies in these nutrients can result in a predisposition for orthopedic diseases such as hip dysplasia.</p>
        <p><strong>Transition to adult food when your puppy is ready.</strong> Puppy food is high in calories to support their rapid growth. To avoid early obesity, transition your puppy to adult food when they approach maturity.</p>
      </div>
    )
  }
}

export default PuppyFeedingTips