import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { ResultsAndRecommendationDescription } from '../../../components/descriptions'

import {
  Navigation,
  HeadingLabel,
  HeadingDescription,
} from '../../../components'

import './style.css'

class Treats extends Component {

  constructor(props) {
    super(props)

    const treats = JSON.parse(localStorage.getItem("treats"))

    this.state = {
      treats: treats,
    }
  }

  render() {
    const { treats } = this.state
    return (
      <div className="container treats-container">
        <ResultsAndRecommendationDescription />
        <div className="row">
          <Navigation activeStep={3} />
        </div>
        <HeadingLabel>Treats do not equal love</HeadingLabel>
        <HeadingDescription>How many treats are too many? It depends. Calorie content of treats can vary from just a few to a few too many - especially if your pet needs to lose weight. Typically, treats should not make up more than 10 - 15% of your pet's diet. Tip: To maintain calorie count and still treat, just portion out a part of your pet's daily food as your dog's treats.</HeadingDescription>
        <div className="row center-align">
          <table className="col s12">
            <thead>
              <tr>
                <th>Treat of Choice</th>
                <th>Related Food</th>
              </tr>
            </thead>

            <tbody>
              { treats && 
                treats.map(treat => {
                  return (
                    <tr key={treat.id}>
                      <td>{treat.name}</td>
                      <td>{`${treat.food_portion} ${treat.unit}`}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
        <div className="row center-align">
          <Link to="/results-and-recommendations" className="btn-flat back-button"><i className="tiny material-icons left">arrow_back</i>Back</Link>
        </div>
      </div>
    )  
  }
}

export default Treats