import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  Form,
  Navigation,
} from '../index'

import {
  PetDetailsDescription,
} from '../descriptions'

class PetDetails extends Component {
  render() {
    const {
      history,
      canSubmit,
      displayBackButton,
      onSubmit,
    } = this.props
    return (
      <div className="container">
        <PetDetailsDescription />
        <div className="row">
          <Navigation activeStep={0} />
        </div>
        <div className="row">
          <Form
            history={history}
            canSubmit={canSubmit}
            displayBackButton={displayBackButton}
            onSubmit={onSubmit}
          >
            {this.props.children}
          </Form>
        </div>
      </div>
    )
  }
}

PetDetails.propTypes = {
  history: PropTypes.object.isRequired,
  canSubmit: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  displayBackButton: PropTypes.bool,
}

PetDetails.defaultProps = {
  displayBackButton: true,
}

export default PetDetails