import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { GlobalHotKeys } from 'react-hotkeys'

import './style.css'

class FormWithSubmitButton extends Component {

  onSubmit = e => {
    e.preventDefault()
    
    if (this.props.canSubmit) {
      this.props.onSubmit(e)
    }
  }

  onGoBack = e => {
    e.preventDefault()
    this.props.history.goBack()
  }

  render() {
    const keyMap = {
      SUBMIT_FORM: "enter",
    }
    const handlers = {
      SUBMIT_FORM: this.onSubmit,
    };
    return (
      <form className="form col s12" onSubmit={this.onSubmit} action={this.props.action}>
        <GlobalHotKeys
          keyMap={keyMap}
          handlers={handlers}
        />
        {this.props.children}
        <div className="row">
          { this.props.displaySubmitButton === true && 
            <input type="submit" id="submit" name="submit" className={`btn submit col s12 m4 offset-m4 ${this.props.canSubmit ? 'valid' : 'disabled'}`} value="Next" />
          }
          { this.props.renderCustomSubmitButton && 
            this.props.renderCustomSubmitButton()
          }
        </div>
        { (this.props.displaySubmitButton === true || this.props.renderCustomSubmitButton) &&
          <div className="row">
            <p className="directions col s6 offset-s3">Hit enter or click next to continue.</p>
          </div>
        }
        <div className="row back-button-holder">
          {
            this.props.displayBackButton &&
            <div onClick={this.onGoBack} className="btn-flat back-button"><i className="tiny material-icons left">arrow_back</i>Back</div>
          }
        </div>
      </form>
    )
  }
}

FormWithSubmitButton.propTypes = {
  history: PropTypes.object.isRequired,
  action: PropTypes.string,
  canSubmit: PropTypes.bool,
  displayBackButton: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  displaySubmitButton: PropTypes.bool,
  renderCustomSubmitButton: PropTypes.func,
}

FormWithSubmitButton.defaultProps = {
  action: "/",
  canSubmit: false,
  displayBackButton: true,
  displaySubmitButton: true,
}

export default FormWithSubmitButton