import React, { Component } from 'react'

import {
  HeadingLabel,
} from '../../../components'

import {
  PetDetailsContainer,
} from '../../../components/containers'

class NameRoute extends Component {

  constructor(props) {
    super(props)

    const name = localStorage.getItem("name") || ""

    this.state = {
      name: name,
      valid: name.length >= 1 ? true : false,
    }
  }

  onNameDidChange = e => {
    this.setState({ name: e.target.value })
    if (e.target.value.length >= 1) {
      this.setState({ valid: true })
    } else {
      this.setState({ valid: false })
    }
  }

  onSubmit = e => {
    localStorage.setItem("name", this.state.name)
    this.props.history.push('/pet-details/select-gender')
  }

  render() {
    const {
      history,
    } = this.props
    const {
      valid,
      name,
    } = this.state

    return (
      <PetDetailsContainer
        history={history}
        canSubmit={valid}
        displayBackButton={false}
        onSubmit={this.onSubmit}
      >
        <HeadingLabel>What is your pet's name?</HeadingLabel>
        <div className="row">
          <input type="text" id="name" ref="name" className="input-field rounded-input col s12 m6 offset-m3" autoComplete="off" placeholder="Type in the name here" name="name" onChange={this.onNameDidChange} defaultValue={name} data-testid="enter-name-input" />
        </div>
      </PetDetailsContainer>
    )  
   }
}

export default NameRoute