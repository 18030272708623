import React, { Component } from 'react'

import {
  Alert,
  MultiSelect,
  HeadingLabel,
} from '../../../components'

import {
  PetDetailsContainer,
} from '../../../components/containers'

class SpayedOrNeutered extends Component {

  constructor(props) {
    super(props)

    const name = localStorage.getItem("name")
    const gender = localStorage.getItem("gender")
    const fixed = localStorage.getItem("fixed")

    this.state = {
      name: name,
      gender: gender,
      fixed: fixed,
      valid: (fixed === "Spayed" && gender === "Female") || (fixed === "Neutered" && gender === "Male"),
      redirectTo: null,
    }
  }

  onSelectFixed = fixed => {
    this.setState({ fixed: fixed })
    if (fixed != null) {
      this.setState({ valid: true })
    } else {
      this.setState({ valid: false })
    }
  }

  onSubmit = e => {
    const {
      fixed,
      gender,
    } = this.state
    localStorage.setItem("fixed", fixed)
    if (gender === "Female" && fixed === "Not Spayed") {
      this.props.history.push('/pet-details/pregnant-or-nursing')
    } else {
      this.props.history.push('/pet-details/select-breed')
    }
  }

  render() {
    const {
      history,
    } = this.props
    const { 
      name, 
      gender, 
      fixed,
      valid,
    } = this.state

    return (
      <PetDetailsContainer
        history={history}
        canSubmit={valid}
        onSubmit={this.onSubmit}
      >
        <HeadingLabel>{`Is ${name} ${gender === "Male" ? "Neutered" : "Spayed"}?`}</HeadingLabel>
        { gender === "Male" &&
          <MultiSelect
            options={["Neutered", "Not Neutered"]}
            onSelect={this.onSelectFixed}
            selected={fixed}
          />
        }
        { gender === "Female" &&
          <MultiSelect
            options={["Spayed", "Not Spayed"]}
            onSelect={this.onSelectFixed}
            selected={fixed}/>
        }
        <Alert title={"Did you know?"}>
          Spayed and neutered pets tend to need fewer calories – or more exercise – to maintain a healthy weight. 
        </Alert>
      </PetDetailsContainer>
    )  
   }
}

export default SpayedOrNeutered