import React, { Component } from 'react'

import {
  MultiSelect,
  HeadingLabel,
} from '../../../components'

import {
  PetDetailsContainer,
} from '../../../components/containers'

import Text from '../../../lib/text'

class SelectGender extends Component {

  constructor(props) {
    super(props)

    const gender = localStorage.getItem("gender")

    this.state = {
      name: localStorage.getItem("name"),
      gender: gender,
      valid: gender != null,
      redirectTo: null,
    }
  }

  onSelectGender = gender => {
    this.setState({ gender: gender })
    if (gender != null) {
      this.setState({ valid: true })
    } else {
      this.setState({ valid: false })
    }
  }

  onSubmit = e => {
    localStorage.setItem("gender", this.state.gender)
    this.props.history.push('/pet-details/spayed-or-neutered')
  }

  render() {
    const name = Text.pluralize(this.state.name)
    const {
      history,
    } = this.props
    const { 
      gender,
      valid,
     } = this.state

    return (
      <PetDetailsContainer
        history={history}
        canSubmit={valid}
        onSubmit={this.onSubmit}
      >
        <HeadingLabel>{`What is ${name} Gender?`}</HeadingLabel>
        <MultiSelect
          options={["Male", "Female"]}
          onSelect={this.onSelectGender}
          selected={gender} />
      </PetDetailsContainer>
    )  
   }
}

export default SelectGender