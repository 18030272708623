import React, { Component } from 'react'
import { Modal } from 'react-materialize'
import PropTypes from 'prop-types'
import { GlobalHotKeys } from 'react-hotkeys'

import './style.css'

class ContactModal extends Component {

  constructor(props) {
    super(props)

    const ownerName = localStorage.getItem("ownerName")
    const ownerEmail = localStorage.getItem("ownerEmail")

    this.state = {
      open: props.open,
      ownerName: ownerName || '',
      ownerEmail: ownerEmail || '',
    }
  }

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
      open: true,
    })
    this.props.onInputFieldChange(e)
  }

  openModal = () => {
    this.setState({
      open: true, 
    })
  }

  hideModal = () => {
    this.setState({
      open: false,
    })
  }

  onSubmit = (e) => {
    e.preventDefault()
    this.props.onSubmit(e)
  }

  emailValid = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }

  render() {
    const keyMap = {
      SUBMIT_FORM: "enter",
    }
    const handlers = {
      SUBMIT_FORM: this.onSubmit,
    };
    const {
      open,
      ownerName,
      ownerEmail,
    } = this.state
    const valid = ownerName.length > 0 && this.emailValid(ownerEmail) ? true : false
    return (
      <div>
        <GlobalHotKeys
          keyMap={keyMap}
          handlers={handlers}
        />
        <Modal id={this.id} className="contact-modal" trigger={this.props.children} open={open}>
          <form onSubmit={this.onSubmit}>
          <i className="material-icons question-icon" onClick={this.hideModal}>cancel</i>
          <h4>Get Your Results + More Tips</h4>
          <div className="container">
            <p className="row col s12 m6">Want more tips to keep your pet safe & healthy? Let's stay in touch. Everything we do at Whistle is backed by science and powered by the extraordinary love we have for our furry friends.</p>
            <label className="row green-label col s12">So close! Enter your name and email to reveal your results.</label>
            <div className="row">
              <input type="text" id="ownerName" className="input-field rounded-input col s12 m4" autoComplete="off" placeholder="Name" name="ownerName" onChange={this.onChange} defaultValue={ownerName} />
              <input type="text" id="ownerEmail" className="input-field rounded-input col s12 m6 offset-m1" autoComplete="off" placeholder="Email" name="ownerEmail" onChange={this.onChange} defaultValue={ownerEmail} />
            </div>
            <div className="row">
              <input type="submit" id="name-input-submit" className={`btn submit col s12 m4 offset-m4 ${valid ? 'valid' : 'disabled'}`} value="Next" onClick={this.onSubmit} />
            </div>
            <p className="directions row col s6 offset-s3">Hit enter or click next to continue.</p>
          </div>
          </form>
          <div className="row back-button-holder">
            <button className="btn-flat back-button" onClick={this.hideModal}><i className="tiny material-icons left modal-close">arrow_back</i>Not Now</button>
          </div>
        </Modal>
      </div>
    )
  }
}

ContactModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onInputFieldChange: PropTypes.func,
}

ContactModal.defaultProps = {
  onInputFieldChange: () => {},
}

export default ContactModal