import React, { Component } from 'react'
import './style.css'

class BCSDetails extends Component {

  render() {
    return (
      <div className="row description-container">
        <h1 className="description-title col s12">Your pet's body condition</h1>
        <p className="description-text col s12 m8 offset-m2">Your dog's body conditioning score will help determine if your pet is too heavy, too lean, or just right. This is a visual and hands-on assessment, so be honest during your evaluation.</p>
      </div>
    )
  }
}

export default BCSDetails
