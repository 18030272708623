import React, { Component } from 'react'
import './style.css'

class FoodAndActivityDetails extends Component {

  render() {
    return (
      <div className="row description-container">
        <h1 className="description-title col s12">YOUR PET'S FOOD & ACTIVITY</h1>
        <p className="description-text col s12 m8 offset-m2">Now share what your dog typically eats, and choose an activity level that best reflects the daily exercise they get.</p>
      </div>
    )
  }
}

export default FoodAndActivityDetails
