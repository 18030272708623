import React, { Component } from 'react'

import {
  Alert,
  MultiSelect,
  HeadingLabel,
} from '../../../components'

import {
  PetDetailsContainer,
} from '../../../components/containers'

class PregnantOrNursing extends Component {

  constructor(props) {
    super(props)

    const pregnantOrNursing = localStorage.getItem("pregnantOrNursing")

    this.state = {
      name: localStorage.getItem("name"),
      pregnantOrNursing: pregnantOrNursing,
      redirectTo: null,
    }
  }

  onSelectPregnantOrNursing = value => {
    this.setState({ pregnantOrNursing: value })
  }

  onSubmit = e => {
    const { pregnantOrNursing } = this.state
    if (pregnantOrNursing !== "No") {
      return;
    }

    localStorage.setItem("pregnantOrNursing", this.state.pregnantOrNursing)
    this.props.history.push('/pet-details/select-breed')
  }

  render() {
    const {
      history,
    } = this.props
    const { 
      name, 
      pregnantOrNursing,
    } = this.state

    const valid = pregnantOrNursing === "No" ? true : false
    
    return (
      <PetDetailsContainer
        history={history}
        canSubmit={valid}
        onSubmit={this.onSubmit}
        displaySubmitButton={pregnantOrNursing === "Yes" ? false : true}
      >
        <HeadingLabel>{`Is ${name} Pregnant or Nursing?`}</HeadingLabel>
        <MultiSelect
          options={["Yes", "No"]}
          onSelect={this.onSelectPregnantOrNursing}
          selected={pregnantOrNursing}
        />
        { pregnantOrNursing === "Yes" &&
          <Alert 
            icon={require('../../../images/nutrition-flow-icons/Whistle_Icons_Dec2019_Oops.png')}
            title={"Consult your vet"}>
            Consult your vet about the best food choice and portioning for your dog. Dogs that are pregnant or nursing have very specific feeding requirements based on their condition and how far along they are.
          </Alert>
        }
      </PetDetailsContainer>
    )  
   }
}

export default PregnantOrNursing