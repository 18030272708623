class Text {

  /**
   * Pluralize a string
   */
  static pluralize = (str, possessive = true) => {
    if (!str) {
      return str
    }
    str += str.slice(-1) === "s" ? "'" : possessive ? "'s" : "s"
    return str
  }
}

export default Text