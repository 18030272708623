import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './style.css'

class Navigation extends Component {

  render() {
    return (
      <div className="navigation row">
        {
          this.props.navigationElements.map((element, index) => {
            let state = "upcoming"
            switch(true) {
              case index < this.props.activeStep:
                state = "completed"
              break

              case index > this.props.activeStep:
                state = "upcoming"
              break;

              default:
                state = "selected"
            }
            return <a href={index <= this.props.activeStep ? element.link : '#'} 
                    className={`col s3 ${state}`}
                    key={`navigation-link-${index}`}>
                      {element.label}
                  </a>
          })
        }
      </div>
    )
  }
}

Navigation.propTypes = {
  activeStep: PropTypes.number,
  navigationElements: PropTypes.array,
}

Navigation.defaultProps = {
  activeStep: 0,
  navigationElements: [
    { link: "/pet-details/enter-name", label: "Your Pet's Details" },
    { link: "/body-condition-score", label: "Body Condition Score" },
    { link: "/food-and-activity-levels/select-food", label: "Food & Activity Levels" },
    { link: "/results-and-recommendations", label: "Results & Recommendations" },
  ]
}

export default Navigation
