import React, { Component } from 'react'

import {
  HeadingLabel,
} from '../../../components'

import {
  PetDetailsContainer,
} from '../../../components/containers'

class EnterAge extends Component {

  constructor(props) {
    super(props)

    const years = localStorage.getItem("years")
    const months = localStorage.getItem("months")

    this.state = {
      name: localStorage.getItem("name"),
      years: years,
      months: months,
      redirectTo: null,
    }
  }

  onChange = e => {
    this.setState({
      [e.target.name]: parseInt(e.target.value),
    })
  }

  isValid = () => {
    const {
      years,
      months,
    } = this.state

    if ((years === 0 && months === 0) ||
        (years < 0 || months < 0) ||
        (years > 30 || months > 12)){
      return false;
    }

    if (years > 0 || months > 0) {
      return true;
    }

  }

  onSubmit = e => {
    localStorage.setItem("years", this.state.years || 0)
    localStorage.setItem("months", this.state.months || 0)
    this.props.history.push('/pet-details/enter-weight')
  }

  render() {
    const {
      history,
    } = this.props
    const { 
      name,
      years,
      months,
    } = this.state
    const valid = this.isValid()
    return (
      <PetDetailsContainer
        history={history}
        canSubmit={valid}
        onSubmit={this.onSubmit}
      >
        <HeadingLabel>{`How old is ${name}?`}</HeadingLabel>
        <div className="row">
          <input type="number" id="years" className="input-field rounded-input col s12 m4 offset-m1 l4 offset-l1" autoComplete="off" placeholder="Years" name="years" defaultValue={years} onChange={this.onChange} />
          <input type="number" id="months" className="input-field rounded-input col s12 m4 offset-m1 l4 offset-l1" autoComplete="off" placeholder="Months" name="months" defaultValue={months} onChange={this.onChange}/>
        </div>
      </PetDetailsContainer>
    )  
   }
}

export default EnterAge