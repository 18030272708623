import React, { Component } from 'react'

import { BCSDescription } from '../../components/descriptions'

import {
  Alert,
  Navigation,
  Form,
  Card,
  HeadingLabel,
  Icon,
} from '../../components'

import './style.css'

class BCS extends Component {

  constructor(props) {
    super(props)

    const bcs = parseInt(localStorage.getItem("bcs"))

    this.state = {
      bcs: bcs,
      redirectTo: null,
    }
  }

  selectBCS = id => {
    this.setState({ bcs: parseInt(id) })
  }

  onSubmit = e => {
    localStorage.setItem("bcs", this.state.bcs)
    this.props.history.push('/food-and-activity-levels/select-food')
  }

  render() {
    const { 
      bcs,
    } = this.state
    const valid = typeof bcs === "number" && bcs >= 2 && bcs <= 4
    const displaySubmitButton = isNaN(bcs) || valid ? true : false
    return (
      <div>
        <div className="container">
          <BCSDescription />
          <div className="row">
            <Navigation activeStep={1} />
          </div>
        </div>
        <HeadingLabel>
        {`Body Condition Score`}
          <a href="https://support.whistle.com/hc/en-us/articles/360039434714" target="_blank" rel="noopener noreferrer" className="bcs-info-link">
            <Icon />
          </a>
        </HeadingLabel>
        <div className="row center-align">
          <div className="col l1 hide-on-med-and-down" />
          <Card
            id={1}
            title={"Very Thin"}
            description={"Ribs and pelvis are visually prominent and easily felt with no padding."}
            selected={bcs === 1 ? true : false}
            onSelected={this.selectBCS}
            containerClass={"s8 offset-s2 m4 l2 bcs-container"}
            image={require('../../images/bcs-illustrations/BCS-2Views_1.png')}
          />
          <Card
            id={2}
            title={"Underweight"}
            description={"Ribs and pelvis are visible and have very minimal padding."}
            selected={bcs === 2 ? true : false}
            onSelected={this.selectBCS}
            containerClass={"s8 offset-s2 offset-s1 m4 l2 bcs-container"}
            image={require('../../images/bcs-illustrations/BCS-2Views_2.png')}
          />
          <Card
            id={3}
            title={"Ideal"}
            description={"Ribs are easy to feel with very modest fat padding. Visible waist when viewed from above, and belly tucks up between ribs and hind legs when viewed from side."}
            selected={bcs === 3 ? true : false}
            onSelected={this.selectBCS}
            containerClass={"s8 offset-s2 m4 l2 bcs-container"}
            image={require('../../images/bcs-illustrations/BCS-2Views_3.png')}
          />
          <Card
            id={4}
            title={"Overweight"}
            description={"Difficult to feel ribs through modern fat cover. No waist when viewed from above, and belly appears rounded when viewed from side (no tuck between ribs and hind legs)."}
            selected={bcs === 4 ? true : false}
            onSelected={this.selectBCS}
            containerClass={"s8 offset-s2 m4 offset-m2 l2 bcs-container"}
            image={require('../../images/bcs-illustrations/BCS-2Views_4.png')}
          />
          <Card
            id={5}
            title={"Obese"}
            description={"Fat deposit over chest, back, and tail/hindquarters. No waistline felt or visible from above. Chest might look or feel swollen, broad back."}
            selected={bcs === 5 ? true : false}
            onSelected={this.selectBCS}
            containerClass={"s8 offset-s2  m4 l2 bcs-container"}
            image={require('../../images/bcs-illustrations/BCS-2Views_5.png')}
          />
        </div>
        <div className="container">
          <div className="row">
            <Form 
              history={this.props.history}
              canSubmit={valid}
              redirectTo={this.state.redirectTo}
              onSubmit={this.onSubmit}
              displaySubmitButton={displaySubmitButton}
              >
              { !displaySubmitButton &&
                <Alert title={"Consult your vet"}>
                  Consult your vet on the best food choice and portioning for your dog's condition. Dogs that are very thin or obese need to be put on a specialized diet in order to get to an ideal weight.
                </Alert>
              }
            </Form>
          </div>
        </div>
      </div>
    )  
   }
}

export default BCS