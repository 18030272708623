import React, { Component } from 'react'

import { FoodAndActivityDescription } from '../../../components/descriptions'
import Text from '../../../lib/text'

import {
  ActivityLevels,
  Navigation,
  Form,
  ContactModal,
  HeadingLabel,
} from '../../../components'

class ActivityLevel extends Component {

  constructor(props) {
    super(props)

    const activityLevel = parseInt(localStorage.getItem("activityLevel"))
    const ownerName = localStorage.getItem("ownerName")
    const ownerEmail = localStorage.getItem("ownerEmail")

    this.state = {
      name: localStorage.getItem("name"),
      ownerName: ownerName,
      ownerEmail: ownerEmail,
      activityLevel: activityLevel,
      modalOpen: false,
      valid: activityLevel ? true : false,
      redirectTo: null,
    }

    this.contactModalRef = React.createRef()
  }

  selectActivityLevel = id => {
    const activityLevel = parseInt(id)
    this.setState({ activityLevel: activityLevel, valid: true })
    localStorage.setItem("activityLevel", activityLevel)
  }

  onInputFieldChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  /**
   * When this form is submitted it opens a modal instead
   * of redirecting
   */
  onSubmit = e => {
    const {
      ownerName,
      ownerEmail,
    } = this.state

    this.contactModalRef.current.openModal()
    localStorage.setItem("activityLevel", this.state.activityLevel)

    if (ownerName.length > 0 && ownerEmail.length > 0 && this.state.modalOpen) {
      this.onModalSubmit()
    }

    this.setState({
      modalOpen: true,
    })
  }

  /**
   * When the modal gets submitted we persist the name/email
   * and redirect to next view
   */
  onModalSubmit = () => {
    const {
      ownerName,
      ownerEmail,
    } = this.state

    if (ownerName.length <= 0 && ownerEmail.length <= 0) {
      return
    }

    localStorage.setItem("ownerName", this.state.ownerName)
    localStorage.setItem("ownerEmail", this.state.ownerEmail)

    this.props.history.push('/results-and-recommendations')
  }

  render() {
    const { 
      name,
      activityLevel,
    } = this.state
    const pluralizedName = Text.pluralize(name)
    const valid = activityLevel ? true : false
    return (
      <div>
        <div className="container">
          <FoodAndActivityDescription />
          <div className="row">
            <Navigation activeStep={2} />
          </div>
        </div>
        <HeadingLabel>{`What is ${pluralizedName} activity level?`}</HeadingLabel>
        <ActivityLevels
          activityLevel={activityLevel}
          onSelected={this.selectActivityLevel}
        />
        <div className="container">
          <Form 
            history={this.props.history}
            canSubmit={valid}
            redirectTo={this.state.redirectTo}
            onSubmit={this.onSubmit}
            displaySubmitButton={false}
            renderCustomSubmitButton={() => (
              <ContactModal
                ref={this.contactModalRef}
                onSubmit={this.onModalSubmit}
                onInputFieldChange={this.onInputFieldChange}
              >
                <input type="submit" id="name-input-submit" className={`btn submit col s12 m4 offset-m4 ${this.state.valid ? 'valid' : 'disabled'}`} value="Next" />
              </ContactModal>
            )}
          />
        </div>
      </div>
    )  
  }
}

export default ActivityLevel