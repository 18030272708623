import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { FoodAndActivityDescription } from '../../../components/descriptions'

import {
  Navigation,
  Form,
  Autocomplete,
  HeadingLabel,
  HeadingDescription,
} from '../../../components'

import { Whistle } from '../../../lib/services'

class Food extends Component {

  constructor(props) {
    super(props)

    const foodId = localStorage.getItem("foodId")
    const foodName = localStorage.getItem("foodName")
    const foodNotFound = localStorage.getItem("foodNotFound") === "true" ? true : false
    var food = localStorage.getItem("food")

    //  food can default to either a food name (as provided by API)
    //  or just the food string which was input from user if food isn't found
    //  in both cases default to a string if it doesn't exist so autosuggest
    //  can parse
    if (foodNotFound) {
      food = food || ''
    } else {
      food = foodName || ''
    }

    this.state = {
      foodId: foodId,
      foodName: foodName,
      food: food,
      foodNotFound: foodNotFound,
      foods: [],
      redirectTo: null,
    }

    Whistle.foodList().then(foods => {
      this.setState({ foods: foods })
      this.chunkFood(foods)
    })
  }

  /**
   * Chunk foods into their groups
   * of their first letter, async
   */
  async chunkFood(food) {
    const chunkedFoods = {}
    food.forEach(f => {
      const firstChar = f.name.charAt(0).toLowerCase()
      if (!chunkedFoods.hasOwnProperty(firstChar)) {
        chunkedFoods[firstChar] = []
      }
      chunkedFoods[firstChar].push(f)
    })
    this.setState({ chunkedFoods: chunkedFoods })
  }

  /**
   * Checking the "food not found" box
   */
  onFoodNotFoundChange = e => {
    this.setState({ 
      foodNotFound: e.target.checked,
      foodId: process.env.REACT_APP_DEFAULT_PET_FOOD_ID,
      foodName: null,
      food: "",
    })
  }

  /**
   * When the food input value changes
   */
  onFoodDidChange = (e, { newValue }) => {
    this.setState({
      food: newValue,
      foodId: null,
      foodName: null,
      foodNotFound: false,
    })
  }

  /**
   * Get suggestions based on value that is
   * currently in the input field
   */
  getSuggestions = value => {
    const { foodNotFound, chunkedFoods } = this.state
    const { numberResults } = this.props

    //  if no food is found don't display the autocomplete dropdown
    if (foodNotFound) {
      return []
    }

    const val = value.trim().toLowerCase()
    const firstChar = val.charAt(0)

    if (!chunkedFoods || !chunkedFoods.hasOwnProperty(firstChar)) {
      return []
    }

    const results = []
    for (let i = 0; i < chunkedFoods[firstChar].length; i++) {
      let food = chunkedFoods[firstChar][i]
      if (food.name.toLowerCase().includes(val)) {
        results.push(food)
      }
      if (results.length >= numberResults) {
        break;
      }
    }
    return results
  }

  /**
   * When a suggestion is clicked, auto suggests
   * then populated the input with this suggestion.
   */
  getSuggestionValue = suggestion => suggestion.name

  /**
   * Called when a suggestion is selected either by
   * keyboard or click
   */
  onSuggestionSelected = (event, { suggestion }) => {
    this.setState({
      foodId: suggestion.id,
      foodName: suggestion.name,
    })
  }

  onSubmit = e => {
    const {
      foodId,
      foodName,
      foodNotFound,
      food,
    } = this.state
    localStorage.setItem("foodId", foodId)
    localStorage.setItem("foodName", foodName)
    localStorage.setItem("foodNotFound", foodNotFound)
    localStorage.setItem("food", food)
    this.props.history.push('/food-and-activity-levels/activity-level')
  }

  render() {
    const { foodName, foodId, food, foodNotFound } = this.state
    const inputProps = {
      placeholder: "Type food here",
      value: food,
      onChange: this.onFoodDidChange,
      className: "input-field rounded-input col s12 m6 offset-m3",
    }
    const valid = (foodName && foodId) || (foodNotFound) ? true : false
    return (
      <div className="container">
        <FoodAndActivityDescription />
        <div className="row">
          <Navigation activeStep={2} />
        </div>
        <div className="row">
          <Form 
            history={this.props.history}
            canSubmit={valid}
            redirectTo={this.state.redirectTo}
            onSubmit={this.onSubmit}
            >
            <HeadingLabel>What brand of food do you feed?</HeadingLabel>
            <HeadingDescription>Search by brand first, then by flavor. Search is alphabetical.</HeadingDescription>
            <div className="row">
              <Autocomplete
                inputProps={inputProps}
                getSuggestions={this.getSuggestions}
                getSuggestionValue={this.getSuggestionValue}
                onSuggestionSelected={this.onSuggestionSelected}
              />
            </div>
            <div className="row center-align">
            <label>
              <input type="checkbox" className="filled-in" checked={foodNotFound === true ? "checked" : ""} name="foodNotFound" onChange={this.onFoodNotFoundChange} />
              <span className="checkbox-label">Food not found</span>
            </label>
            </div>
          </Form>
        </div>
      </div>
    )  
  }
}

Food.propTypes = {
  numberResults: PropTypes.number,
}

Food.defaultProps = {
  numberResults: 40,
}

export default Food
