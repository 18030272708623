class Whistle {

  static breedList = () => {
    return fetch(`/api/breeds/dogs`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
      }
    })
    .then(response => {
      return response.json()
    })
    .then(data => { 
      return data.breeds
    });
  }

  static foodList = (query) => {
    return fetch(`/api/pet_foods${query !== undefined ? '?query=' + query : ''}`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
      }
    })
    .then(response => {
      return response.json()
    })
    .then(data => {
      return data
    })
  }

  static treatList = () => {
    return fetch(`/api/pet_foods?type=dog_treat`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
      }
    })
    .then(response => {
      return response.json()
    })
    .then(data => {
      return data
    })
  }

  static activitySuggestion = (breedId, year, weight, weightUnit) => {
    return fetch(`/api/nutrition/${breedId}/activity_suggestion?age_year=${year}&weight=${weight}&weight_unit=${weightUnit}`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
      }
    })
    .then(response => {
      return response.json()
    })
    .then(data => {
      return data
    })
  }

  static submit = (dogDetails) => {
    return fetch(`/api/submit`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(dogDetails),
    })
    .then(response => {
      return response.json()
    })
    .then(data => {
      return data
    })
  }
}

export default Whistle
