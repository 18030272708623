import React, { Component } from 'react'
import './style.css'

class PetDetails extends Component {

  render() {
    return (
      <div className="row description-container">
        <h1 className="description-title col s12">About your pet</h1>
        <p className="description-text col s12 m8 offset-m2">Let's start by gathering a few essential details about your pet.</p>
      </div>
    )
  }
}

export default PetDetails
