import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './style.css'

class MultiSelect extends Component {

  onClick = (option, event) => {
    event.preventDefault()
    this.props.onSelect(option)
  }

  render() {
    const { 
      selected,
      addedClasses,
    } = this.props
    return (
      <div className={`multi-select center-align ${addedClasses}`}>
        {
          this.props.options.map(option => {
            return <button 
                    className={`btn ${option === selected ? 'selected' : ''}`} 
                    onClick={(e) => this.onClick(option, e)}
                    key={`multi-select-${option}`}
                    >
                    {option}
                  </button>
          })
        }
      </div>
    )
  }
}

MultiSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  selected: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  addedClasses: PropTypes.string,
}

export default MultiSelect
