import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Whistle, DogDetails, ActivityLevels as ActivityLevelsService } from '../../lib/services'

import { Card, Placeholder } from '../index'
import './style.css'

class ActivityLevels extends Component {

  constructor(props) {
    super(props)

    const breedId = localStorage.getItem("breedId")
    const year = localStorage.getItem("years")
    const weight = localStorage.getItem("weight")
    const weightUnits = DogDetails.normalizedWeightUnit(localStorage.getItem("weightUnits"))

    this.state = {
      breedId: breedId,
    }

    Whistle.activitySuggestion(breedId, year, weight, weightUnits)
    .then(data => {
      this.setState({
        activityLevels: data,
      })
    })
  }

  selectActivityLevel = id => {
    this.props.onSelected(id)
  }

  render() {
    const {
      activityLevel,
    } = this.props
    const {
      activityLevels,
    } = this.state
    const cardPlaceholder = <Placeholder style={{ height: "22px", display: "block", width: "100%", "marginBottom": "5px" }} />
    return (
      <div className="row center-align">
        <Card
          id={1}
          title={"Couch Creature"}
          description={"Just a quick stroll or romp a day."}
          descriptionTitle={activityLevels ? ActivityLevelsService.formatActivityLevel(activityLevels.couch_potato) : cardPlaceholder}
          containerClass={"s6 m3 l2 offset-l2 activity-level-container"}
          selected={activityLevel === 1 ? true : false}
          onSelected={this.selectActivityLevel}
          image={require('../../images/activity-levels/Activity_1.png')}
        />
        <Card
          id={2}
          title={"Regular Mover"}
          description={"Regular walks + occasional hike or visit to park."}
          descriptionTitle={activityLevels ? ActivityLevelsService.formatActivityLevel(activityLevels.regular_mover) : cardPlaceholder}
          containerClass={"s6 m3 l2 activity-level-container"}
          selected={activityLevel === 2 ? true : false}
          onSelected={this.selectActivityLevel}
          image={require('../../images/activity-levels/Activity_2.png')}
        />
        <Card
          id={3}
          title={"Weekend Warrior"}
          description={"Reliably shatters activity goals with extended adventures."}
          descriptionTitle={activityLevels ? ActivityLevelsService.formatActivityLevel(activityLevels.weekend_warrior) : cardPlaceholder}
          containerClass={"s6 m3 l2 activity-level-container"}
          selected={activityLevel === 3 ? true : false}
          onSelected={this.selectActivityLevel}
          image={require('../../images/activity-levels/Activity_3.png')}
        />
        <Card
          id={4}
          title={"The Athlete"}
          description={"An extreme runner, hiker, sledder, etc."}
          descriptionTitle={activityLevels ? ActivityLevelsService.formatActivityLevel(activityLevels.the_athlete) : cardPlaceholder}
          containerClass={"s6 m3 l2 activity-level-container"}
          selected={activityLevel === 4 ? true : false}
          onSelected={this.selectActivityLevel}
          image={require('../../images/activity-levels/Activity_4.png')}
        />
      </div>
    )
  }
}

ActivityLevels.propTypes = {
  activityLevel: PropTypes.number,
  onSelected: PropTypes.func.isRequired,
}

ActivityLevels.defaultProps = {
  activityLevel: 0,
}

export default ActivityLevels
